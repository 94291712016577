<template>
  <div class="page">
    <div class="c-unrecognized c-unrecognized_h-800">
      <div class="c-unrecognized__body c-unrecognized__body_top-208">
        <img src = "@/assets/images/email-exclamation mark.svg" alt="email-check">
        <h2 class="c-unrecognized__title"><span class="c-unrecognized__title_black">Unrecognized</span> Device / Location</h2>
        <p class="c-unrecognized__content">
          Hello, it seems that you are attempting to sign into your Sparrow account<br />
          from an unrecognized device or location. For security purposes,<br />
          a verification email has been sent to your email address.
        </p>
        <a  class="c-unrecognized__link" @click='routerRedirect("/signin")'>Return to Home >></a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Unrecognized',
  components: {
  },
  data() {
    return {
    }
  },
}
</script>
